@import "../mdl/variables";
@import "variables";

.mbm-toc {
}

.mbm-toc > ul {
    border-left: solid 3px $toc-border-color;
    padding-left: $mbm-vertical-space;
    line-height: 28px;
    list-style-type: none;
    margin-left: 10px;
}

.mbm-toc li {
  font-size: 16px;
  list-style: none;
}

.mbm-toc a {
  color: $toc-text-color;
  font-weight: 400;
  text-decoration: none;
}

.mbm-back-to {
  color: rgba(0, 0, 0, 0.3);
  display: block;
  text-decoration: none;
}

.mbm-back-to > i {
  vertical-align: bottom;
}

.mbm-back-to:hover {
  color: rgba(0, 0, 0, 0.5);
}

.mbm-back-to-top {
  color: rgba(0, 0, 0, 0.1);
  float: right;
}

.mbm-back-to-top:hover {
  color: rgba(0, 0, 0, 0.3);
}

////////// Phone //////////

@media (max-width: $grid-tablet-breakpoint - 1) {

}

////////// Tablet //////////

@media (min-width: $grid-tablet-breakpoint) and (max-width: $grid-desktop-breakpoint - 1) {

}

////////// Desktop //////////

@media (min-width: $grid-desktop-breakpoint) {

}

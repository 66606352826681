/*
 * Button Menus
 * http://stackoverflow.com/questions/29307971/css-grid-of-squares-with-flexbox
 */


@import "variables";

.mbm-search-menu {
  margin-bottom: 40px;
  margin-top: 20px;
  text-align: center;
}

.mbm-search-menu .mdl-textfield {
  font-size: 32px;
  min-width: 48px;
  min-height: 48px;
  width: 80%;
}

.mbm-search-menu .mdl-textfield__input,
.mbm-search-menu .mdl-textfield__label {
  font-size: 32px;
  text-align: center;
}

@import "../mdl/color-definitions";
@import "../mdl/variables";
@import "../mdl/functions";


/* ==========  Fonts  ========== */

$logo_font: 'Muli', sans-serif !default;

/* ==========  COLORS  ========== */
/* Ref: https://material.google.com/style/color.html#color-color-palette */

$mbm-color-1: $palette-indigo-A700 !default;
$mbm-color-2: $palette-purple-A700 !default;
$mbm-color-3: $palette-green-A700 !default;
$mbm-color-4: $palette-amber-A700 !default;
$mbm-color-5: $palette-light-green-A700 !default;
$mbm-color-6: $palette-red-A400 !default;
$mbm-color-7: $palette-yellow-A700 !default;
$mbm-color-8: $palette-pink-A400 !default;

$mbm-color-1-h: $palette-indigo-A400 !default;
$mbm-color-2-h: $palette-purple-A400 !default;
$mbm-color-3-h: $palette-green-A400 !default;
$mbm-color-4-h: $palette-amber-A400 !default;
$mbm-color-5-h: $palette-light-green-A400 !default;
$mbm-color-6-h: $palette-red-A200 !default;
$mbm-color-7-h: $palette-yellow-A400 !default;
$mbm-color-8-h: $palette-pink-A200 !default;

$mbm-colors: (
  $palette-indigo-A700,
  $palette-purple-A700,
  $palette-green-A700,
  $palette-amber-A700,
  $palette-light-green-A700,
  $palette-red-A400,
  $palette-yellow-A700,
  $palette-pink-A400
);

$mbm-colors-h: (
  $palette-indigo-A400,
  $palette-purple-A400,
  $palette-green-A400,
  $palette-amber-A400,
  $palette-light-green-A400,
  $palette-red-A200,
  $palette-yellow-A400,
  $palette-pink-A200
);

/* ==========  Card  ========== */

$mbm-card-background-color-1: unquote("rgb(#{$mbm-color-1})") !default;
$mbm-card-background-color-2: unquote("rgb(#{$mbm-color-2})") !default;
$mbm-card-background-color-3: unquote("rgb(#{$mbm-color-3})") !default;
$mbm-card-background-color-4: unquote("rgb(#{$mbm-color-4})") !default;
$mbm-card-background-color-5: unquote("rgb(#{$mbm-color-5})") !default;
$mbm-card-background-color-6: unquote("rgb(#{$mbm-color-6})") !default;
$mbm-card-background-color-7: unquote("rgb(#{$mbm-color-7})") !default;
$mbm-card-background-color-8: unquote("rgb(#{$mbm-color-8})") !default;

$mbm-card-background-color-1-h: unquote("rgb(#{$mbm-color-1-h})") !default;
$mbm-card-background-color-2-h: unquote("rgb(#{$mbm-color-2-h})") !default;
$mbm-card-background-color-3-h: unquote("rgb(#{$mbm-color-3-h})") !default;
$mbm-card-background-color-4-h: unquote("rgb(#{$mbm-color-4-h})") !default;
$mbm-card-background-color-5-h: unquote("rgb(#{$mbm-color-5-h})") !default;
$mbm-card-background-color-6-h: unquote("rgb(#{$mbm-color-6-h})") !default;
$mbm-card-background-color-7-h: unquote("rgb(#{$mbm-color-7-h})") !default;
$mbm-card-background-color-8-h: unquote("rgb(#{$mbm-color-8-h})") !default;

$mbm-card-background-colors: ();
$mbm-card-background-colors-h: ();

@each $mbm-color in $mbm-colors {
  $mbm-card-background-colors: append(
    $mbm-card-background-colors,
    unquote("rgb(#{$mbm-color})"),
    'comma'
  );
}

@each $mbm-color-h in $mbm-colors-h {
  $mbm-card-background-colors-h: append(
    $mbm-card-background-colors-h,
    unquote("rgb(#{$mbm-color-h})"),
    'comma'
  );
}

/* ==========  Menu  ========== */

$menu-button-sm-width: 98%;
$menu-button-sm-margin: 1%; // 4 margins because two squares

$menu-button-md-breakpoint: 590px;
$menu-button-md-width: 46%;
$menu-button-md-margin: 0.01 * $menu-button-md-breakpoint; // 4 * 1% + 2 * 46%

$menu-button-lg-breakpoint: 800px;
$menu-button-lg-width: 30%;
$menu-button-lg-margin: 0.01 * $menu-button-lg-breakpoint;

$menu-button-xl-breakpoint: 1000px;
$menu-button-xl-width: 23%;
$menu-button-xl-margin: 0.01 * $menu-button-xl-breakpoint;

/* ==========  Contents  ========== */

$toc-border-color: unquote("rgb(#{$color-primary})") !default;
$toc-text-color: unquote("rgb(#{$color-accent})") !default;

/* ==========  Spacings  ========== */
$mbm-vertical-space: 20px;

$mbm-horizontal-space: 16px;

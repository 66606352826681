@import "variables";

.mbm-layout {

}

.mbm-layout .mdl-layout-title a {
    color: #fff;
    font-weight: inherit;
    text-decoration: none;
}

.mbm-layout__header {
  margin-bottom: $mbm-vertical-space;
}

.mbm-header-logo,
.mbm-drawer-logo {
    font-family: $logo_font;
}

.mdl-navigation__link--icon i {
  font-size: 24px;
  vertical-align: middle;
}

.mbm-layout__header-row--second-row {
  padding-left: 16px; // To match the one above

  .mdl-navigation__link:first-of-type {
    padding-left: 0;
  }

  .mdl-navigation__link:last-of-type {
    padding-right: 0;
  }
}

.mbm-layout__drawer .mdl-navigation__link > i {
  padding-right: $mbm-horizontal-space;
}

.mbm-social-nav {
  opacity: 1;
  transition: opacity 0.5s linear;
}

.mbm-tabs {
  margin-bottom: 20px;
}

.mbm-tabs__tab {
  width: 50%;
}

.mbm-tabs__panel {
  font-size: 13px;
  padding: 20px;
}

.mdl-mega-footer--link-list a,
.mdl-mega-footer__link-list a {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    display: inline-block;
}

.mbm-dialog {
  width: 80%;
}

////////// Phone //////////

@media (max-width: $grid-tablet-breakpoint - 1) {
  .mbm-layout__header-row--second-row {
    display: none;
  }
}

////////// Tablet //////////

@media (min-width: $grid-tablet-breakpoint) and (max-width: $grid-desktop-breakpoint - 1) {
  .mbm-social-nav {
    opacity: 0;
  }
}


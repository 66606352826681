@import "../mdl/variables";

@media (max-width: 820px) { // 820px = 640px + some padding (just guess)
  .mbm-video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
  }
  .mbm-video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/*
 * Pagination (for use with list.js which generates html)
 */
 
 @import "../mdl/variables";
 @import "../mdl/mixins";

 .pagination {
   text-align: center;
 }

.pagination li {
  display:inline-block;
  padding:5px;
}

// The button component. Defaults to a flat button.
.pagination li a {
  background: transparent;
  border: none;
  border-radius: 50%;
  color: $button-secondary-color;
  position: relative;
  height: $button-height;
  margin: 0;
  min-width: $button-height;
  padding: 0;
  display: inline-block;
  @include typo-button();
  overflow: hidden;
  will-change: box-shadow;
  transition: box-shadow 0.2s $animation-curve-fast-out-linear-in,
              background-color 0.2s $animation-curve-default,
              color 0.2s $animation-curve-default;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  line-height: $button-height;
  vertical-align: middle;

  &::-moz-focus-inner {
    border: 0;
  }

  &:hover {
    background-color: $button-hover-color;
  }

  &:focus:not(:active) {
    background-color: $button-focus-color;
  }

  &:active {
    background-color: $button-active-color;
  }

  &.mdl-button--colored {
    color: $button-primary-color-alt;

    &:focus:not(:active) {
      background-color: $button-focus-color-alt;
    }
  }
}

.pagination li.active a {
  @include shadow-2dp();

  &:active {
    @include shadow-4dp();
    background-color: $button-active-color-alt;
  }

  &:focus:not(:active) {
    @include focus-shadow();
    background-color: $button-active-color-alt;
  }

  background: $button-primary-color-alt;
  color: $button-secondary-color-alt;

  &:hover {
    background-color: $button-hover-color-alt;
  }
}
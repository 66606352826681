@import "../mdl/variables";
@import "variables";

.mbm-section-description {
  color: rgba(0, 0, 0, 0.3);
  font-style: italic;
}

.mbm-section-description a {
  color: rgba(0, 0, 0, 0.3);
  font-weight: normal;
}

/*
 * Button Menus
 * http://stackoverflow.com/questions/29307971/css-grid-of-squares-with-flexbox
 */


@import "variables";

.mbm-menu-container {
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row;
  flex-wrap: wrap;
  justify-content: center;
}

.mbm-menu-card-outer {
  flex: 0 0 $menu-button-sm-width;
  height: auto;
  margin: $menu-button-sm-margin;
}

.mbm-menu-card {

  text-decoration: none;
  height: 100%;
  width: 100%;
}

.mbm-menu-card > .mdl-card__title {
  align-items: flex-start;
  color: #fff;
}

.mbm-menu-card > .mdl-card__title > h4 {
  margin-top: 0;
  pointer-events: none;
}

.mbm-menu-card > .mdl-card__supporting-text {
  color: #fff;
}

@for $i from 1 through length($mbm-card-background-colors) {
  .mbm-menu-card--mbm-#{$i},
  .mbm-menu-card[data-cardtype="mbm-#{$i}"] {
    background: nth($mbm-card-background-colors, $i);
  }
  .mbm-menu-card--mbm-#{$i}-alt,
  .mbm-menu-card[data-cardtype="mbm-#{$i}-alt"] {
    background: white;
    border: 5px solid nth($mbm-card-background-colors, $i);
    > .mdl-card__title,
    > .mdl-card__supporting-text {
      color: nth($mbm-card-background-colors, $i);
    }
  }
}

@for $i from 1 through length($mbm-card-background-colors-h) {
  .mbm-menu-card--mbm-#{$i}:hover,
  .mbm-menu-card[data-cardtype="mbm-#{$i}"]:hover {
    background: nth($mbm-card-background-colors-h, $i);
  }
  .mbm-menu-card--mbm-#{$i}-alt.mdl-card:hover,
  .mbm-menu-card[data-cardtype="mbm-#{$i}-alt"]:hover {
    border: 5px solid nth($mbm-card-background-colors-h, $i);
    > .mdl-card__title,
    > .mdl-card__supporting-text {
      color: nth($mbm-card-background-colors-h, $i);
    }
  }
}

@media (max-width: $grid-tablet-breakpoint - 1) {
  .mbm-menu-card-outer {
    flex: 0 0 $menu-button-sm-width;
    margin: $menu-button-sm-margin;
  }
}

@media (min-width: $menu-button-md-breakpoint) {
  .mbm-menu-container {
    margin: - $menu-button-md-margin;
  }
  .mbm-menu-card-outer:before {
      content: '';
      float: left;
      padding-top: 100%;
  }
  .mbm-menu-card-outer {
    flex: 0 0 $menu-button-md-width;
    margin: $menu-button-md-margin;
  }
}

@media (min-width: $menu-button-lg-breakpoint) {
  .mbm-menu-card-outer {
    flex: 0 0 $menu-button-lg-width;
    margin: $menu-button-lg-margin;
  }
}

@media (min-width: $menu-button-xl-breakpoint) {
  .mbm-menu-card-outer {
    flex: 0 0 $menu-button-xl-width;
    margin: $menu-button-xl-margin;
  }
}


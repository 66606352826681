@import "variables";

.mbm-container {
    max-width: 1600px;
    width: calc(100% - 16px);
    margin: 0 auto;
}

.mbm-bottom-space {
  margin-bottom: $mbm-vertical-space;
}

.mbm-center {
  text-align: center;
}

.mbm-horizontal-space {
  margin-left: $mbm-horizontal-space / 2;
  margin-right: $mbm-horizontal-space / 2;
}
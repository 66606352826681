@font-face {
  font-family: 'mrbartonmaths';
  src:  url('/fonts/mrbartonmaths.eot?9zll5z');
  src:  url('/fonts/mrbartonmaths.eot?9zll5z#iefix') format('embedded-opentype'),
    url('/fonts/mrbartonmaths.ttf?9zll5z') format('truetype'),
    url('/fonts/mrbartonmaths.woff?9zll5z') format('woff'),
    url('/fonts/mrbartonmaths.svg?9zll5z#mrbartonmaths') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="mbm-icon-"], [class*=" mbm-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'mrbartonmaths' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mbm-icon-diagnostic-questions:before {
  content: "\e900";
}
.mbm-icon-social-twitter:before {
  content: "\e901";
}
.mbm-icon-wordpress:before {
  content: "\e903";
}
.mbm-icon-podcast:before {
  content: "\e91c";
}
.mbm-icon-youtube:before {
  content: "\ea9d";
}
.mbm-icon-pinterest:before {
  content: "\ead1";
}


@import "../mdl/variables";

.mbm-content {
  border-radius: 2px;
  padding: 80px 56px;
  margin-bottom: 80px;
}

.mdl-grid.mdl-grid--no-spacing > .mdl-cell.mbm-content {
  margin-bottom: 80px;
}

////////// Phone //////////

@media (max-width: $grid-tablet-breakpoint - 1) {
  .mbm-content {
    padding: 10px;
  }
}

////////// Tablet //////////

@media (min-width: $grid-tablet-breakpoint) and (max-width: $grid-desktop-breakpoint - 1) {

}

////////// Desktop //////////

@media (min-width: $grid-desktop-breakpoint) {

}
